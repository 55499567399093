import React, { useState } from 'react';
import { Container, TextField, Button, CircularProgress, FormControl, Grid, InputLabel, Select, MenuItem } from '@mui/material';
import ApiClient from '../../../config/configAxios';
import { toast } from 'react-toastify';

// Data for the dropdown options
// const abroadCourses = ['IELTS', 'PTE', 'GMAT', 'DSAT', 'DET'];
// const nepalCourses = ['SOMAT', 'KUMAT', 'BBAFI', 'CMAT', 'KUUMAT'];
const qualificationOptions = ['+2 College', 'Bachelors', 'Others'];

const StudentRegistration = ({ onRegister }) => {
  const [formData, setFormData] = useState({
    s_name: '',
    email: '',
    s_contact: '',
    s_address: '',
    graduated_college: '',
    admission_on: '',
    qualification: '',
    destination: '',
    preparationTest: '' // Now a TextField instead of a dropdown
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTimeout(() => {
      onRegister(formData);

      ApiClient.post('/register', formData)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            toast.success('Student Registered.', {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            toast.error('Sorry, Something went wrong.', {
              position: 'top-right',
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      setIsSubmitting(false);
      setFormData({
        s_name: '',
        email: '',
        s_contact: '',
        s_address: '',
        graduated_college: '',
        admission_on: '',
        qualification: '',
        destination: '',
        preparationTest: '',
      });
    }, 2000);
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <h2>Student Registration</h2>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
          {/* Left Column (5 Fields) */}
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Student Name"
              variant="outlined"
              fullWidth
              name="s_name"
              value={formData.s_name}
              onChange={handleChange}
              required
              style={{ marginBottom: '16px' }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              style={{ marginBottom: '16px' }}
            />
            <TextField
              label="Contact Number"
              variant="outlined"
              fullWidth
              name="s_contact"
              value={formData.s_contact}
              onChange={handleChange}
              required
              style={{ marginBottom: '16px' }}
            />
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              name="s_address"
              value={formData.s_address}
              onChange={handleChange}
              required
              style={{ marginBottom: '16px' }}
            />
            <TextField
              label="Graduated College"
              variant="outlined"
              fullWidth
              name="graduated_college"
              value={formData.graduated_college}
              onChange={handleChange}
              required
              style={{ marginBottom: '16px' }}
            />
          </Grid>

          {/* Right Column (4 Fields) */}
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Admission Date"
              type="date"
              variant="outlined"
              fullWidth
              name="admission_on"
              value={formData.admission_on}
              onChange={handleChange}
              required
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginBottom: '16px' }}
            />

            {/* Qualification Field (Dropdown) */}
            <FormControl fullWidth style={{ marginBottom: '16px' }}>
              <InputLabel>Qualification</InputLabel>
              <Select
                value={formData.qualification}
                onChange={handleChange}
                label="Qualification"
                name="qualification"
                sx={{
                  textAlign: 'left',
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      textAlign: 'left',
                    },
                  },
                }}
              >
                {qualificationOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Destination Field (Dropdown) */}
            <FormControl fullWidth style={{ marginBottom: '16px' }}>
              <InputLabel>Destination</InputLabel>
              <Select
                value={formData.destination}
                onChange={handleChange}
                label="Destination"
                name="destination"
                sx={{
                  textAlign: 'left', // Align the selected value to the left
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      textAlign: 'left', // Align dropdown items to the left
                    },
                  },
                }}
              >
                <MenuItem value="Study Abroad">Study Abroad</MenuItem>
                <MenuItem value="Study Nepal">Study In Nepal</MenuItem>
              </Select>
            </FormControl>

            {/* Preparation Test Field (TextField, no dependency on Destination) */}
            <TextField
              label="Preparation Test"
              variant="outlined"
              fullWidth
              name="preparationTest"
              value={formData.preparationTest}
              onChange={handleChange}
              required
              style={{ marginBottom: '16px' }}
            />
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ width: 'auto', marginTop: '20px' }}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
        </Button>
      </form>
    </Container>
  );
};

export default StudentRegistration;
