
// import React, { useState, useEffect } from 'react';
// import { TextField, Button, IconButton, Grid } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ApiClient from '../../../config/configAxios';
// import { toast } from 'react-toastify';
// import Guidelines from './guidelines';

// const CountryForm = ({ hideForm, editData }) => {
//   const [formData, setFormData] = useState({
//     countryName: '',
//     admissionGuideline: [{ topic: '', description: '', subtopics: [{ name: '', description: '' }] }],
//     reasonToChoose: '',
//     popularUniversities: [''],
//     faq: [{ faqQuestion: '', faqAnswer: '' }],
//     flag: null,
//   });

//   useEffect(() => {
//     if (editData) {
//       setFormData({
//         countryName: editData.countryName || '',
//         // admissionGuideline: editData.admissionGuideline || [{ topic: '', description: '', subtopics: [{ name: '', description: '' }] }],
//         // reasonToChoose: editData.reasonToChoose || '',
//         // popularUniversities: editData.popularUniversities || [''],
//         // faq: editData.faq || [{ faqQuestion: '', faqAnswer: '' }],

//         admissionGuideline: Array.isArray(editData.admissionGuideline) && editData.admissionGuideline.length > 0
//         ? editData.admissionGuideline
//         : [{ topic: '', description: '', subtopics: [{ name: '', description: '' }] }],
//       reasonToChoose: editData.reasonToChoose || '',
//       popularUniversities: Array.isArray(editData.popularUniversities) && editData.popularUniversities.length > 0
//         ? editData.popularUniversities
//         : [''],
//       faq: Array.isArray(editData.faq) && editData.faq.length > 0
//         ? editData.faq
//         : [{ faqQuestion: '', faqAnswer: '' }],
//       flag: null, // You might want to set the flag here as well (if editData has it)
//     });
//   }
// }, [editData])
//   const handleChange = (e, index, type) => {
//     const { name, value, files } = e.target;

//     if (name === 'flag' && files.length > 0) {
//       setFormData({
//         ...formData,
//         flag: files[0],
//       });
//     } else if (name === 'faqQuestion' || name === 'faqAnswer') {
//       const updatedFaq = [...formData.faq];
//       updatedFaq[index][name] = value;
//       setFormData({
//         ...formData,
//         faq: updatedFaq,
//       });
//     } else if (type === 'popularUniversities') {
//       const updatedUniversities = [...formData.popularUniversities];
//       updatedUniversities[index] = value;
//       setFormData({
//         ...formData,
//         popularUniversities: updatedUniversities,
//       });
//     } else {
//       setFormData({
//         ...formData,
//         [name]: value,
//       });
//     }
//   };

//   const handleAddFaq = () => {
//     setFormData({
//       ...formData,
//       faq: [...formData.faq, { faqQuestion: '', faqAnswer: '' }],
//     });
//   };

//   const handleDeleteFaq = (index) => {
//     const updatedFaq = formData.faq.filter((_, i) => i !== index);
//     setFormData({
//       ...formData,
//       faq: updatedFaq,
//     });
//   };

//   const handleAddUniversity = () => {
//     setFormData({
//       ...formData,
//       popularUniversities: [...formData.popularUniversities, ''],
//     });
//   };

//   const handleDeleteUniversity = (index) => {
//     const updatedUniversities = formData.popularUniversities.filter((_, i) => i !== index);
//     setFormData({
//       ...formData,
//       popularUniversities: updatedUniversities,
//     });
//   };

//   const handleSubmit = () => {
//     if (!formData.flag) {
//       toast.error('Flag is required!', {
//         position: 'top-right',
//         autoClose: 5000,
//         closeOnClick: true,
//         pauseOnHover: true,
//       });
//       return;
//     }

//     const submissionData = new FormData();
//     submissionData.append('countryName', formData.countryName);
//     submissionData.append('admissionGuideline', JSON.stringify(formData.admissionGuideline));
//     submissionData.append('reasonToChoose', formData.reasonToChoose);
//     submissionData.append('faq', JSON.stringify(formData.faq));
//     submissionData.append('popularUniversities', JSON.stringify(formData.popularUniversities));
//     submissionData.append('flag', formData.flag);

//     const request = editData
//       ? ApiClient.patch(`/destinedCountry/${editData.id}`, submissionData, {
//         headers: { 'Content-Type': 'multipart/form-data' },
//       })
//       : ApiClient.post('/destinedCountry', submissionData, {
//         headers: { 'Content-Type': 'multipart/form-data' },
//       });

//     request.then((response) => {
//       if (response.data.success) {
//         toast.success(editData ? 'Country updated.' : 'Country Added.', {
//           position: 'top-right',
//           autoClose: 5000,
//           closeOnClick: true,
//           pauseOnHover: true,
//         });
//       } else {
//         toast.error('Sorry, Something wrong.', {
//           position: 'top-right',
//           autoClose: 5000,
//           closeOnClick: true,
//           pauseOnHover: true,
//         });
//       }
//     }).catch((err) => {
//       console.log(err);
//       toast.error('An error occurred. Please try again.', {
//         position: 'top-right',
//         autoClose: 5000,
//         closeOnClick: true,
//         pauseOnHover: true,
//       });
//     });

//     setFormData({
//       countryName: '',
//       admissionGuideline: [{ topic: '', description: '', subtopics: [{ name: '', description: '' }] }],
//       reasonToChoose: '',
//       popularUniversities: [''],
//       faq: [{ faqQuestion: '', faqAnswer: '' }],
//       flag: null,
//     });
//     hideForm();
//   };

//   return (
//     <Grid container spacing={2} direction="column" sx={{ maxWidth: 400, padding: '20px' }}>
//       <Grid item>
//         <TextField
//           label="Country Name"
//           name="countryName"
//           value={formData.countryName}
//           onChange={handleChange}
//           fullWidth
//           required
//         />
//       </Grid>

//       <Guidelines guidelines={formData.admissionGuideline} setGuidelines={(guidelines) => setFormData({ ...formData, admissionGuideline: guidelines })} />

//       <Grid item>
//         <TextField
//           label="Reason To Choose"
//           name="reasonToChoose"
//           value={formData.reasonToChoose}
//           onChange={handleChange}
//           fullWidth
//           required
//         />
//       </Grid>

//       {formData.popularUniversities.map((university, index) => (
//         <Grid item key={index}>
//           <TextField
//             label={`Popular University ${index + 1}`}
//             value={university}
//             onChange={(e) => handleChange(e, index, 'popularUniversities')}
//             fullWidth
//             required
//             InputProps={{
//               endAdornment: (
//                 <>
//                   <IconButton onClick={handleAddUniversity} size="small" style={{ marginRight: '8px' }}>
//                     <AddIcon />
//                   </IconButton>
//                   {formData.popularUniversities.length > 1 && (
//                     <IconButton onClick={() => handleDeleteUniversity(index)} size="small">
//                       <DeleteIcon />
//                     </IconButton>
//                   )}
//                 </>
//               ),
//             }}
//           />
//         </Grid>
//       ))}

//       {formData.faq.map((faq, index) => (
//         <Grid item key={index}>
//           <TextField
//             label={`FAQ Question ${index + 1}`}
//             name="faqQuestion"
//             value={faq.faqQuestion}
//             onChange={(e) => handleChange(e, index, 'faq')}
//             fullWidth
//             required
//             InputProps={{
//               endAdornment: (
//                 <>
//                   <IconButton onClick={handleAddFaq} size="small" style={{ marginRight: '8px' }}>
//                     <AddIcon />
//                   </IconButton>
//                   {formData.faq.length > 1 && (
//                     <IconButton onClick={() => handleDeleteFaq(index)} size="small">
//                       <DeleteIcon />
//                     </IconButton>
//                   )}
//                 </>
//               ),
//             }}
//           />
//           <TextField
//             label={`FAQ Answer ${index + 1}`}
//             name="faqAnswer"
//             value={faq.faqAnswer}
//             onChange={(e) => handleChange(e, index, 'faq')}
//             fullWidth
//             required
//           />
//         </Grid>
//       ))}

//       <Grid item>
//         <input
//           type="file"
//           name="flag"
//           onChange={handleChange}
//           accept="image/*"
//           style={{ width: '100%' }}
//           required
//         />
//       </Grid>

//       <Grid item>
//         <Button variant="contained" onClick={handleSubmit} fullWidth>
//           {editData ? 'Update Country' : 'Add Country'}
//         </Button>
//       </Grid>
//     </Grid>
//   );
// };

// export default CountryForm;





import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, Grid,  Typography, Container, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiClient from '../../../config/configAxios';
import { toast } from 'react-toastify';
import Guidelines from './guidelines';

const CountryForm = ({ hideForm, editData }) => {
  const [formData, setFormData] = useState({
    countryName: '',
    admissionGuideline: [{ topic: '', description: '', subtopics: [{ name: '', description: '' }] }],
    reasonToChoose: '',
    popularUniversities: [''],
    faq: [{ faqQuestion: '', faqAnswer: '' }],
    flag: null,
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        countryName: editData.countryName || '',
        admissionGuideline: Array.isArray(editData.admissionGuideline) && editData.admissionGuideline.length > 0
          ? editData.admissionGuideline
          : [{ topic: '', description: '', subtopics: [{ name: '', description: '' }] }],
        reasonToChoose: editData.reasonToChoose || '',
        popularUniversities: Array.isArray(editData.popularUniversities) && editData.popularUniversities.length > 0
          ? editData.popularUniversities
          : [''],
        faq: Array.isArray(editData.faq) && editData.faq.length > 0
          ? editData.faq
          : [{ faqQuestion: '', faqAnswer: '' }],
        flag: null,
      });
    }
  }, [editData]);

  const handleChange = (e, index, type) => {
    const { name, value, files } = e.target;

    if (name === 'flag' && files.length > 0) {
      setFormData({
        ...formData,
        flag: files[0],
      });
    } else if (name === 'faqQuestion' || name === 'faqAnswer') {
      const updatedFaq = [...formData.faq];
      updatedFaq[index][name] = value;
      setFormData({
        ...formData,
        faq: updatedFaq,
      });
    } else if (type === 'popularUniversities') {
      const updatedUniversities = [...formData.popularUniversities];
      updatedUniversities[index] = value;
      setFormData({
        ...formData,
        popularUniversities: updatedUniversities,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAddFaq = () => {
    setFormData({
      ...formData,
      faq: [...formData.faq, { faqQuestion: '', faqAnswer: '' }],
    });
  };

  const handleDeleteFaq = (index) => {
    const updatedFaq = formData.faq.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      faq: updatedFaq,
    });
  };

  const handleAddUniversity = () => {
    setFormData({
      ...formData,
      popularUniversities: [...formData.popularUniversities, ''],
    });
  };

  const handleDeleteUniversity = (index) => {
    const updatedUniversities = formData.popularUniversities.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      popularUniversities: updatedUniversities,
    });
  };

  const handleSubmit = () => {
    if (!formData.flag) {
      toast.error('Flag is required!', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }

    const submissionData = new FormData();
    submissionData.append('countryName', formData.countryName);
    submissionData.append('admissionGuideline', JSON.stringify(formData.admissionGuideline));
    submissionData.append('reasonToChoose', formData.reasonToChoose);
    submissionData.append('faq', JSON.stringify(formData.faq));
    submissionData.append('popularUniversities', JSON.stringify(formData.popularUniversities));
    submissionData.append('flag', formData.flag);

    const request = editData
      ? ApiClient.patch(`/destinedCountry/${editData.id}`, submissionData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      : ApiClient.post('/destinedCountry', submissionData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

    request.then((response) => {
      if (response.data.success) {
        toast.success(editData ? 'Country updated.' : 'Country Added.', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error('Sorry, Something wrong.', {
          position: 'top-right',
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }).catch((err) => {
      console.log(err);
      toast.error('An error occurred. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    });

    setFormData({
      countryName: '',
      admissionGuideline: [{ topic: '', description: '', subtopics: [{ name: '', description: '' }] }],
      reasonToChoose: '',
      popularUniversities: [''],
      faq: [{ faqQuestion: '', faqAnswer: '' }],
      flag: null,
    });
    hideForm();
  };

  return (
    <Container maxWidth="md">
      {/* <Paper elevation={3} sx={{ padding: '20px', marginTop: '20px' }}> */}
        <Typography variant="h4" gutterBottom>
          {editData ? 'Edit Country' : 'Add Country'}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Country Name"
              name="countryName"
              value={formData.countryName}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Guidelines guidelines={formData.admissionGuideline} setGuidelines={(guidelines) => setFormData({ ...formData, admissionGuideline: guidelines })} />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Reason To Choose"
              name="reasonToChoose"
              value={formData.reasonToChoose}
              onChange={handleChange}
              fullWidth
              required
              multiline
              rows={4}
            />
          </Grid>

          {formData.popularUniversities.map((university, index) => (
            <Grid item xs={12} key={index}>
              <TextField
                label={`Popular University ${index + 1}`}
                value={university}
                onChange={(e) => handleChange(e, index, 'popularUniversities')}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton onClick={handleAddUniversity} size="small" style={{ marginRight: '8px' }}>
                        <AddIcon />
                      </IconButton>
                      {formData.popularUniversities.length > 1 && (
                        <IconButton onClick={() => handleDeleteUniversity(index)} size="small">
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
          ))}

          {formData.faq.map((faq, index) => (
            <Grid item xs={12} key={index}>
              <TextField
                label={`FAQ Question ${index + 1}`}
                name="faqQuestion"
                value={faq.faqQuestion}
                onChange={(e) => handleChange(e, index, 'faq')}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton onClick={handleAddFaq} size="small" style={{ marginRight: '8px' }}>
                        <AddIcon />
                      </IconButton>
                      {formData.faq.length > 1 && (
                        <IconButton onClick={() => handleDeleteFaq(index)} size="small">
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
              <TextField
                label={`FAQ Answer ${index + 1}`}
                name="faqAnswer"
                value={faq.faqAnswer}
                onChange={(e) => handleChange(e, index, 'faq')}
                fullWidth
                required
                multiline
                rows={4}
                sx={{ mt: 2 }}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <input
              type="file"
              name="flag"
              onChange={handleChange}
              accept="image/*"
              style={{ width: '100%' }}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleSubmit} sx={{ mr: 2 }}>
                {editData ? 'Update Country' : 'Add Country'}
              </Button>
              <Button variant="outlined" onClick={hideForm}>
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      {/* </Paper> */}
    </Container>
  );
};

export default CountryForm;