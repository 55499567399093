
import React from 'react';
import { TextField, IconButton, Grid, Box, Button, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const Guidelines = ({ guidelines, setGuidelines }) => {
  const handleGuidelineChange = (index, field, value) => {
    const updatedGuidelines = [...guidelines];
    updatedGuidelines[index][field] = value;
    setGuidelines(updatedGuidelines);
  };

  const handleSubtopicChange = (index, subIndex, field, value) => {
    const updatedGuidelines = [...guidelines];
    updatedGuidelines[index].subtopics[subIndex][field] = value;
    setGuidelines(updatedGuidelines);
  };

  const handleAddTopic = () => {
    setGuidelines([
      ...guidelines,
      { topic: '', description: '', subtopics: [{ name: '', description: '' }] },
    ]);
  };

  const handleDeleteTopic = (index) => {
    const updatedGuidelines = [...guidelines];
    updatedGuidelines.splice(index, 1);
    setGuidelines(updatedGuidelines);
  };

  const handleAddSubtopic = (index) => {
    const updatedGuidelines = [...guidelines];
    updatedGuidelines[index].subtopics.push({ name: '', description: '' });
    setGuidelines(updatedGuidelines);
  };

  const handleDeleteSubtopic = (index, subIndex) => {
    const updatedGuidelines = [...guidelines];
    updatedGuidelines[index].subtopics.splice(subIndex, 1);
    setGuidelines(updatedGuidelines);
  };

  return (
    <>
      {guidelines.map((item, index) => (
        <Box key={index} mb={3}>
          <fieldset style={{ padding: '16px', borderRadius: '8px' }}>
            <legend style={{ fontSize: '1.2rem' }}>
              Addmission Guidelines {index + 1}
            </legend>

            <Grid container spacing={1} mb={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Topic Name"
                  value={item.topic}
                  onChange={(e) => handleGuidelineChange(index, 'topic', e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Topic Description"
                  value={item.description}
                  onChange={(e) => handleGuidelineChange(index, 'description', e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            {item.subtopics.map((subtopic, subIndex) => (
              <Box key={subIndex} mb={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={`Subtopic ${subIndex + 1} Name`}
                      value={subtopic.name}
                      onChange={(e) =>
                        handleSubtopicChange(index, subIndex, 'name', e.target.value)
                      }
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={`Subtopic ${subIndex + 1} Description`}
                      value={subtopic.description}
                      onChange={(e) =>
                        handleSubtopicChange(index, subIndex, 'description', e.target.value)
                      }
                      fullWidth
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <IconButton onClick={() => handleAddSubtopic(index)}>
                    <AddIcon />
                  </IconButton>
                  {item.subtopics.length > 1 && (
                    <IconButton onClick={() => handleDeleteSubtopic(index, subIndex)} >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))}

            <Divider sx={{ my: 2 }} />

            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
              <Button>
                {/* <IconButton onClick={() => handleAddSubtopic(index)} sx={{ mr: 2 }}>
                  <AddIcon /> Add Subtopic
                </IconButton> */}
                {guidelines.length > 1 && (
                  <IconButton onClick={() => handleDeleteTopic(index)} >
                    {/* <DeleteIcon />  */}
                    Delete Topic
                  </IconButton>
                )}
              </Button>
              <Button
                onClick={handleAddTopic}
                sx={{ alignSelf: 'center' }}
              >
                Add Topic
              </Button>
            </Box>
          </fieldset>
        </Box>
      ))}
    </>
  );
};

export default Guidelines;