import React, { useEffect } from 'react';
import { Container } from "@mui/material";
import DashboardTabs from '../Dashboard/dash_board';
import { useNavigate } from 'react-router-dom';

const AdminDashboardPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if admin details exist in localStorage
    const admin = localStorage.getItem('admin');
    if (!admin) {
      // Redirect to login if no admin details are found
      navigate('/');
    }
  }, [navigate]);

  return (
    <Container maxWidth={false} disableGutters>
      <DashboardTabs />
    </Container>
  );
}

export default AdminDashboardPage;
