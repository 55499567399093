import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField, Button, TablePagination } from '@mui/material';
import { Select, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiClient from '../../../config/configAxios';
import { Buffer } from 'buffer';
import { toast } from "react-toastify";

const StudentTable = ({ students:listRecord,column,url}) => {
  const [isEditing, setIsEditing] = useState(null);
  const [students,setStudents] = React.useState(listRecord);

  // console.log("List record for delete:",listRecord);
  // console.log("Record for delete:",students);
  const [formData, setFormData] = useState({
    s_name: '',
    email: '',
    s_contact: '',
    s_address:'',
    graduated_college: '',
    qualification: '',
    admission_on: '',
    admitted_college: '',
    program: '',
    feedback: '',
    alumini: 'Yes',
    s_photo: '',
    destination: '',
    preparationTest: ''
  });

  const [page, setPage] = useState(0); // Page state
  const [rowsPerPage, setRowsPerPage] = useState(25); // Rows per page state

  const handleEditClick = (student) => {
    setIsEditing(student.sid);
    setFormData({ ...student });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Log form data whenever a field is updated
    // console.log("Form data updated:", { ...formData, [name]: value });
  };

  const handleSave = () => {
    // console.log("Saving data:", formData); // Log the data to be saved
    const updatedStudentRecord = new FormData();
    updatedStudentRecord.append("s_name",formData.s_name);
    updatedStudentRecord.append("email",formData.email);
    updatedStudentRecord.append("s_contact",formData.s_contact);
    updatedStudentRecord.append("s_address",formData.s_address);
    updatedStudentRecord.append("graduated_college",formData.graduated_college);
    updatedStudentRecord.append("admission_on",formData.admission_on);
    updatedStudentRecord.append("qualification",formData.qualification);
    updatedStudentRecord.append("destination",formData.destination);
    updatedStudentRecord.append("preparationTest",formData.preparationTest);
    updatedStudentRecord.append("admitted_college",formData.admitted_college);
    updatedStudentRecord.append("program",formData.program);
    updatedStudentRecord.append("feedback",formData.feedback);
    updatedStudentRecord.append("alumini",formData.alumini);
    updatedStudentRecord.append("s_photo",formData.s_photo);

    ApiClient.patch(`/register/${formData.sid}`,updatedStudentRecord,{
      headers:{
        'Content-Type': 'multipart/form-data',
      }
    }).then((response)=>{
      if(response.data.success){
        toast.success("Student updated.",{
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }else{
        toast.error("Sorry, Something wrong.",{
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
    // onUpdate(formData);
    setIsEditing(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    // formData.s_photo=file;
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        s_photo: file,
      }));
    }
  };
  React.useEffect(()=>{
    setStudents(listRecord);
  },[listRecord]);

  const handleDelete = (sid) => {
    // console.log("Deleting student with SID:", sid); // Log SID of the student being deleted
    // console.log("check id:",sid);
    if (window.confirm("Are Your Sure ?")) {
      // console.log("True");
      ApiClient.delete(`${url}/${sid}`).then((response)=>{
        // console.log(response);
          if(response.data.success){
            const updatedRows = students.filter(row=>row.sid !==sid);
            setStudents(updatedRows);
            toast.warning("Deleted Successfully.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }else{
            toast.error("Something went wrong.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
          }).catch((error)=>{
            console.log(error);
          })
        
    }else{
      console.log("Not okay");
    }
  } 
  // Slice the students array based on the current page and rows per page
  const currentStudents = students.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <TableContainer 
        sx={{ maxHeight: 640,maxWidth: {xs: "100%",sm:"80%",md:"96%"} }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {
                column.map((col)=>
                  <TableCell
                    sx={{
                      fontWeight: "600"
                      }}
                  >{col}</TableCell>
                )
              }
              <TableCell sx={{
                fontWeight: "600"
                }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentStudents.map((student) => (
              <TableRow key={student.sid}>
                <TableCell>
                  {
                    student.sid 
                  }
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="s_name"
                      value={formData.s_name}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.s_name
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.email
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="s_contact"
                      value={formData.s_contact}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.s_contact
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="s_address"
                      value={formData.s_address}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.s_address
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="graduated_college"
                      value={formData.graduated_college}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.graduated_college
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="admission_on"
                      value={formData.admission_on}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.admission_on
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="qualification"
                      value={formData.qualification}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.qualification
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="destination"
                      value={formData.destination}
                      onChange={handleChange}
                      sx={{ width: '200px' }} 
                    />
                  ) : (
                    student.destination
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="preparation_test"
                      value={formData.preparationTest}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.preparationTest
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="admitted_college"
                      value={formData.admitted_college}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.admitted_college
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="program"
                      value={formData.program}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.program
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                    <TextField
                      variant="outlined"
                      name="feedback"
                      value={formData.feedback}
                      onChange={handleChange}
                      sx={{ width: '200px' }}  
                    />
                  ) : (
                    student.feedback
                  )}
                </TableCell>
                <TableCell>
                  {isEditing === student.sid ? (
                  <Select
                    name="alumini"
                    value={formData.alumini ?? "Yes"}
                    onChange={handleChange}
                    sx={{ width: '200px' }}
                    variant="outlined"
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                  ) : (
                    student.alumini
                  )}
                </TableCell>
                <TableCell>
                    {isEditing === student.sid ? (
                      <input
                        type="file"
                        onChange={handlePhotoChange}
                        accept="image/*"
                        sx={{ width: '200px' }}  
                      />
                    ) : (
                      student.s_photo ? (
                        <img
                          src={`data:image/${student.photo_format};base64,${Buffer.from(student.s_photo).toString('base64')}`}
                          alt="Student"
                          style={{ width: '50px', height: '50px' }}
                        />
                      ) : (
                        <span>No Image</span> // Fallback if s_photo is undefined
                      )
                    )}
                </TableCell>


                
                <TableCell>
                  {isEditing === student.sid ? (
                    <Button onClick={handleSave} variant="contained" color="primary">
                      Save
                    </Button>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEditClick(student)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(student.sid)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[25,50]}
        component="div"
        count={students.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default StudentTable;
