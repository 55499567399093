import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';

import ApiClient from '../../config/configAxios';

import { toast } from "react-toastify";
export default function StickyHeadTable() {
  const column = ["S.N","Student Name","Contact","Message"];
  // console.log("information received :")
  // console.log("From:",listRecord);
  // console.log(column);
  // console.log(url);
  // console.log("End of information");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows,setRows] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(()=>{
    ApiClient.get(`/studentQuery`).then((response)=>{
      console.log(response)
      setRows(response.data.queryDetail);
    }).catch((error)=>{
      console.log(error);
    })
  },[]);

  const handleDeleteAction = (id)=>{
    // console.log("check id:",id);

    if (window.confirm("Are Your Sure ?")) {
        ApiClient.delete(`/studentQuery/${id}`).then((response)=>{
          console.log(response);
            if(response.data.success){
              const updatedRows = rows.filter(row=>row.id !==id);
              setRows(updatedRows);
              toast.warning("Deleted Successfully.",{
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }else{
            toast.error("Something went wrong.",{
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
        }).catch((error)=>{
          console.log(error);
        })
    }
  }
  // console.log(rows);
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' ,mb:6}}>
      <TableContainer sx={{ maxHeight: 640,maxWidth: {xs: "100%",sm:"80%",md:"96%"} }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
            >
              {column.map((column,index) => (
                <TableCell
                  key={index}
              
                  sx={{
                      minWidth: "100px",
                      fontWeight: "600",
                      fontFamily: "calibri"
                  }}
                >
                  {column}
                </TableCell>
                
              ))}
              <TableCell sx={{
                fontWeight: "600"
                }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              textAlign:"center"
            }}
          >
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow 
                  hover role="checkbox" tabIndex={-1} key={row.dcId}>
                    {Object.keys(row).map((col,index) =>  (
                      
                        <TableCell key={index} 
                          sx={{
                            fontSize:"12px",
                            // backgroundColor:"yellow",
                            paddingLeft:"10px"
                          }}
                        >
                            {row[col]}
                        </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        height:"100%",
                        // backgroundColor:"green",
                        // display:"flex",
                        // justifyContent: "space-between",
                        // alignItems: "center",
                        flexDirection:"row",
                        gap: 1,
                        // borderRight: "1px solid black"
                      }}
                  >
                    <DeleteIcon sx={{
                        color: "red",
                        cursor: "pointer",
                        border: "1px solid #00000050",
                        // backgroundColor:"gray",
                        borderRadius: "50%",
                        padding: "2px",
                        height: "30px",
                        width: "30px"
                    }}
                    onClick={()=>handleDeleteAction(row.id)}
                    />
                  </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25,50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
            maxWidth: {xs: "100%",sm:"80%",md:"96%"} ,
        }}
      />
    </Paper>
  );
}